<template>
  <section id="brand-statistics">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedBrand" placeholder="Select brand" label="title" :options="allBrands"
            @input="changeSelectedBrand" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showBrands" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Brands statistics
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-brands-statistics" />
          </h4>
          <b-popover target="popover-brands-statistics" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="brandsTable.currentPage"
          :items="brandsTable.items" :fields="brandsTable.fields" :sort-by.sync="brandsTable.sortBy"
          :sort-desc.sync="brandsTable.sortDesc" :sort-direction="brandsTable.sortDirection"
          :filter="brandsTable.filter" :filter-included-fields="brandsTable.filterOn"
          @sort-changed="brandsTableSortChanged" @row-clicked="brandsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="brandsTable.currentPage" :total-rows="brandsTable.totalRows" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0" @change="handleBrandsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="brand-details-modal" size="xl" :title="brandName" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetBrandDetailsModal"
      @shown="onBrandDetailsModalShown">
      <b-overlay :show="showDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-overlay :show="showProductsByBrand" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Products by brand
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-products-brand" />
              </h4>
              <b-popover target="popover-products-brand" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="productsByBrandTable.currentPage"
              :items="productsByBrandTable.items" :fields="productsByBrandTable.fields"
              :sort-by.sync="productsByBrandTable.sortBy" :sort-desc.sync="productsByBrandTable.sortDesc"
              :sort-direction="productsByBrandTable.sortDirection" :filter="productsByBrandTable.filter"
              :filter-included-fields="productsByBrandTable.filterOn" @sort-changed="productsByBrandTableSortChanged" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="productsByBrandTable.currentPage" :total-rows="productsByBrandTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleProductsByBrandTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
        <b-row>
          <b-col xl="6">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Turnover over years
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-turnover-years" />
                </h4>
                <b-popover target="popover-turnover-years" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="turnoverByYearChart" :options="turnoverByYearChart" style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
          <b-col xl="6">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Sales by platform
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-sales-platform" />
                </h4>
                <b-popover target="popover-sales-platform" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="topPlatformsChart" :options="topPlatformsChart" style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="6">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Products by sales
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-products-sales" />
                </h4>
                <b-popover target="popover-products-sales" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="productsByTurnoverChart" :options="productsByTurnoverChart"
                style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
          <b-col xl="6">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Categories by sales
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-categories-sales" />
                </h4>
                <b-popover target="popover-categories-sales" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="productsByCategoryChart" :options="productsByCategoryChart"
                style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardText,
  BCardHeader,
  BPopover,
  BCard,
  BOverlay,
  BModal,
  BSpinner,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardText,
    BCardHeader,
    BPopover,
    BOverlay,
    BModal,
    BSpinner,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    vSelect,
    ECharts,
  },
  data() {
    return {
      showBrands: true,
      showDetails: true,
      showProductsByBrand: true,
      showExportStart: false,
      oldDateRange: '',
      dateRange: '',
      productsByPlatform: false,
      showProductsByTurnover: true,
      showProductsByCategory: true,
      showBrandTurnoverByYear: true,
      selectedBrand: '',
      brandID: '',
      brandName: '',
      allBrands: [],
      productSKU: '',
      queryParamsBrands: {},
      queryParamsProductsByBrand: {},
      queryParamsBrandDetails: {},
      brandsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'brand', label: 'brand', sortable: true },
          {
            key: 'turnover_prev_year',
            label: 'turnover previous year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_curr_year',
            label: 'turnover current year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_diff',
            label: 'turnover difference',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productsByBrandTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      topPlatformsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      productsByTurnoverChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      productsByCategoryChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      turnoverByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    try {
      await this.getAllBrands();
      await this.getBrandRating();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllBrands() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-manufacturers/`, {});
        this.allBrands = response.data.results.map(item => item.value);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getBrandRating() {
      this.showBrands = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/brand-rating/`, this.queryParamsBrands);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.brandsTable.items = results;
          this.brandsTable.totalRows = results[0].count * 2;
        }
        else {
          this.brandsTable.items = [];
          this.brandsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showBrands = false;
      }
    },
    async getProductsByBrand() {
      this.showProductsByBrand = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-by-brand/`, this.queryParamsProductsByBrand);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsByBrandTable.items = results;
          this.productsByBrandTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsByBrandTable.items = [];
          this.productsByBrandTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByBrand = false;
      }
    },
    async getProductsByTurnover() {
      this.showProductsByTurnover = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/brand-products-by-turnover/`, this.queryParamsBrandDetails);
        this.productsByTurnoverChart.series[0].data = response.data.results.map(item => {
          return {
            value: item.turnover,
            name: item.sku,
          };
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByTurnover = false;
      }
    },
    async getProductsByPlatform() {
      this.productsByPlatform = true;
      this.showDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/brand-products-by-platform/`, this.queryParamsBrandDetails);
        const results = response.data.results;
        this.topPlatformsChart.xAxis.data = results.map(item => item.platform);
        this.topPlatformsChart.series = {
          name: 'Turnover',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.productsByPlatform = false;
        this.showDetails = false;
      }
    },
    async getProductsByCategory() {
      this.showProductsByCategory = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/brand-products-by-category/`, this.queryParamsBrandDetails);
        this.productsByCategoryChart.series[0].data = response.data.results.map(item => {
          return {
            value: item.turnover,
            name: item.category,
          };
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByCategory = false;
      }
    },
    async getBrandTurnoverByYear() {
      this.showBrandTurnoverByYear = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/brand-turnover-by-year/`, this.queryParamsBrandDetails);
        const results = response.data.results;
        this.turnoverByYearChart.xAxis.data = results.map(item => item.year);
        this.turnoverByYearChart.series[0] = {
          name: 'Turnover YTD',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${params.value.toLocaleString('de-DE')} YTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover_ytd),
        };
        this.turnoverByYearChart.series[1] = {
          name: 'Turnover',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showBrandTurnoverByYear = false;
      }
    },
    onBrandDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.turnoverByYearChart.resize();
        this.$refs.topPlatformsChart.resize();
        this.$refs.productsByTurnoverChart.resize();
        this.$refs.productsByCategoryChart.resize();
      });
    },
    brandsRowClicked(row) {
      this.brandID = row.brand_id;
      this.brandName = row.brand;
      this.queryParamsProductsByBrand.brand_id = this.brandID;
      this.queryParamsBrandDetails.brand_id = this.brandID;
      this.getProductsByBrand();
      this.getProductsByTurnover();
      this.getProductsByPlatform();
      this.getProductsByCategory();
      this.getBrandTurnoverByYear();
      this.$refs['brand-details-modal'].show();
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsBrands.from_date = dateRange[0];
        this.queryParamsBrands.to_date = dateRange[1];
        this.queryParamsProductsByBrand.from_date = dateRange[0];
        this.queryParamsProductsByBrand.to_date = dateRange[1];
        this.queryParamsBrandDetails.from_date = dateRange[0];
        this.queryParamsBrandDetails.to_date = dateRange[1];
        await this.getBrandRating();
        this.oldDateRange = this.dateRange;
      }
    },
    async changeSelectedBrand() {
      this.queryParamsBrands.brand = this.selectedBrand;
      await this.getBrandRating();
    },
    async brandsTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsBrands.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsBrands.ordering = value.sortBy;
      }
      await this.getBrandRating();
    },
    async productsByBrandTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsProductsByBrand.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProductsByBrand.ordering = value.sortBy;
      }
      await this.getProductsByBrand();
    },
    async handleBrandsTablePageChange(value) {
      this.queryParamsBrands.page = value;
      await this.getBrandRating();
    },
    async handleProductsByBrandTablePageChange(value) {
      this.queryParamsProductsByBrand.page = value;
      await this.getProductsByBrand();
    },
    resetBrandDetailsModal() {
      this.brandID = '';
      this.brandName = '';
      this.productSKU = '';
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-brand-rating/`, this.queryParamsBrands);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'BrandsStatistics.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
